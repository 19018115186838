<template>
  <section class="whychoose-1 secpadd">
    <div class="container">
      <div class="row quote1top">
        <div class="col-md-12">
          <div class="col-md-6 m-auto">
            <form @submit.prevent="send">
              <div class="fh-form request-form">
                <div class="field">
                  <label>{{ getTranslation('email') }}</label>
                  <input type="email" class="form-control" :class="{'is-invalid':$v.email.$error}" v-model="email">
                  <div v-if="$v.email.$error" class="invalid-feedback">
                    {{ getTranslation('requiredField') }}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="col-md-6">
            <p @click.prevent="send" class="field submit">
              <input :value="getTranslation('submit')" class="fh-btn" type="submit">
            </p>
          </div>
          <div class="col-md-6">
            <button class="btn btn-default" @click="gotoBack">{{ getTranslation('backTo') }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: ""
    }
  },
  created() {

  },
  computed: {
    type() {
      return parseInt(this.$route.query.type || 0);
    }
  },
  methods: {
    send() {
      this.$v.email.$touch();

      if ( this.$v.email.$invalid )
        return;

      this.axios.post(this.$urls.auth.forgotPassword.url, { email: this.email })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: this.getTranslation('Reset link is sent to your email'),
              text: this.getTranslation('Please, check your email')
            });

            this.$router.push({ path: '/' })
          }).catch(() => {
      });
    },
    gotoBack() {
      let routeName = 'portal.login';

      if ( this.type === 1 )
        routeName = 'admin.login';

      this.$router.push({ name: routeName }).then(() => {
        window.location.reload();
      });
    }
  },
  validations: {
    email: {
      required
    }
  }
}
</script>

<style scoped>

</style>